// all tags

import * as React from "react";
import kebabCase from "lodash.kebabcase";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../../components/Layout";

class TagsPage extends React.Component<
  {
    data: {
      allMarkdownRemark: { group };
      site: {
        siteMetadata: { title };
      };
    };
  },
  { counter: number }
> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      counter: 0
    };
  }

  inc() {
    this.setState({ counter: this.state.counter + 1 });
  }

  render() {
    return (
      <Layout>
        <section>
          <Helmet title={`Tags | ${this.props.data.site.siteMetadata.title}`} />
          <div>
            <div>
              {this.state.counter}
              <button onClick={this.inc.bind(this)}>inc</button>
              <div>
                <h1>Tags</h1>
                <ul>
                  {this.props.data.allMarkdownRemark.group.map(tag => (
                    <li key={tag.fieldValue}>
                      <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                        {tag.fieldValue} ({tag.totalCount})
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
